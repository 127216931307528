import React from "react";
import { CorporateContactJsonLd, LocalBusinessJsonLd, NextSeo, SocialProfileJsonLd ,LogoJsonLd,WebPageJsonLd, CourseJsonLd, ProductJsonLd, FAQPageJsonLd} from "next-seo";

export default class NextSeoModule extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
            <NextSeo
                title={this.props.title}
                description={this.props.description}
                canonical={`https://www.learnsoft.org/${this.props.url}`}
                additionalMetaTags={[
                    {
                      name: "keywords",
                      content: `${this.props.keyword}`,
                    },
                  ]}
                openGraph={{
                    type: 'website',
                    locale:'en_US',
                    url: `https://www.learnsoft.org/${this.props.url}`,
                    title: `${this.props.title}`,
                    description: `${this.props.description}`,
                    images: [
                        {
                            url: `https://www.learnsoft.org/${this.props.img}`,
                            secureUrl:`https://www.learnsoft.org/${this.props.img}`,
                            width: 400,
                            height: 600,
                            type:'image/webp',
                            alt: `${this.props.title} Course Image`,
                        },
                    ],
                    site_name: "learnsoft.org",
                }}
                twitter={{
                  handle: "@learnsoft.org",
                  site: "@Learnsoftorg",
                  cardType: "summary_large_image",
                }}
            />
            <LogoJsonLd
            id="https://www.learnsoft.org/logob.webp"
            name = "Logo - learnsoft.org"
            logo="https://www.learnsoft.org/logob.webp"
            url="https://www.learnsoft.org"
           />
             <WebPageJsonLd
              type="Breadcrumbs"
          id="https://www.learnsoft.org"
          url="https://www.learnsoft.org"
          name={`Best software training institue learnsoft.org - ${this.props.breadcrums || 'All Courses'}`}
          description={this.props.description}
          breadcrumb={{
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name":`${this.props.breadcrums || "All Courses"}`,
                "item": `https://www.learnsoft.org/${this.props.url}`
              }
            ]
          }}
          potentialAction={{
            "@type": "SearchAction",
            "target": "https://www.learnsoft.org/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }}
        />
            <CorporateContactJsonLd
                url="https://www.learnsoft.org"
                logo="https://www.learnsoft.org/logob.webp"
                name = "Contact - learnsoft.org"
                contactPoint={[
                    {
                        telephone: '+917825888899',
                        contactType: 'Enquiry',
                        areaServed: ["IN", "US", "CA"],
                        availableLanguage: ["English", "Tamil"],
                    }
                ]}
            />
            <SocialProfileJsonLd
                type="Organization"
                id="https://www.learnsoft.org"
                name="learnsoft.org"
                url="https://www.learnsoft.org"
                sameAs={[
                    'https://www.facebook.com/Learnsoft.org/',
                    'https://x.com/Learnsoftorg',
                    'https://www.linkedin.com/in/learnsoft-organization-it-training-internship-and-placement',
                    'https://www.instagram.com/learnsoft_org/'
                    
                ]}
            />
            <LocalBusinessJsonLd
                type="LocalBusiness"
                priceRange = "₹"
                id="https://www.learnsoft.org/contact"
                name="learnsoft.org"
                description="learnsoft Provide Training, Internship and Placement"
                url="https://www.learnsoft.org/contact"
                telephone="+917825888899"
                address={{
                    "@type": "PostalAddress",
                    streetAddress: 'No.4/643,ADM Tower Second Floor,VOC street, OMR,Thoraippakkam',
                    addressLocality: 'Chennai',
                    addressRegion: 'Tamil Nadu',
                    postalCode: '600097',
                    addressCountry: 'INDIA',
                }}
                geo={{
                    "@type": "GeoCoordinates",
                    latitude: '12.9322375',
                    longitude: '80.2302378',
                }}
                images={[
                    `https://www.learnsoft.org/${this.props.img}`
                ]}
                openingHoursSpecification ={[
                    {
                      "@type": "OpeningHoursSpecification",
                      "opens": "08:00",
                      "closes": "21:00",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday"
                      ]
                    }
                  ]}
                  aggregateRating={{
                    "@type": "AggregateRating",
                    ratingValue: "4.9",
                    ratingCount:"12345",
                    reviewCount: "19276",
                    bestRating: "5",
                    worstRating: "1",
                  }}
            />

             {/* Education Schema (EducationalOrganization) */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "EducationalOrganization",
              name: "Learnsoftorg",
              url:`https://www.learnsoft.org/${this.props.url}`,
              logo: "https://www.learnsoft.org/logob.webp",
              description:
                "Learnsoftorg is a leading software training institute offering certification courses in IT, software development, and digital marketing.",
              address: {
                "@type": "PostalAddress",
                streetAddress:
                  "No.4/643, ADM Tower Second Floor, VOC Street, OMR, Thoraipakkam",
                addressLocality: "Chennai",
                addressRegion: "Tamil Nadu",
                postalCode: "600097",
                addressCountry: "IN",
              },
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+917825888899",
                contactType: "customer support",
                areaServed: ["IN", "US", "CA"],
                availableLanguage: ["English", "Tamil"],
              },
              sameAs: [
                "https://www.facebook.com/Learnsoft.org/",
                "https://x.com/Learnsoftorg",
                "https://www.linkedin.com/in/learnsoft-organization-it-training-internship-and-placement",
                "https://www.instagram.com/learnsoft_org/",
              ],
            }),
          }}
        />
            {/* Course Schema Implementation

            <CourseJsonLd
                courseName = {this.props.title}
                description={this.props.description}
                provider={{
                  name: "learnsoft organization",
                  type: "Organization",
                  url: `https://www.learnsoft.org`,
                }}
                // course review snippets

                // aggregateRating={{
                //   "@type": "AggregateRating",
                //   ratingValue: "4.9",
                //   ratingCount:"12345",
                //   reviewCount: "15989",
                //   bestRating: "5",
                //   worstRating: "1",
                // }}
                offers={{
                  "@type": "Offer",
                  url: `https://www.learnsoft.org/${this.props.url}`,
                  availability: "http://schema.org/InStock",
                  price: "₹", 
                  priceCurrency: "INR",
                  category: "paid",
                }}
                hasCourseInstance={{
                  "@type": "CourseInstance",
                  name: `${this.props.title} Batch 2025`,
                  description: this.props.description,
                  courseMode: "Blended",
                  courseWorkload: "PT5H",
                  instructor: {
                    "@type": "Person",
                    name: "Mohamed",
                  },
                }}
            
     /> */}
  
           {/* Product Schema Implementation */}
          <ProductJsonLd
         type="Product"
         productName={this.props.title } // Explicit productName
         name="Best Software Training Institute in Chennai" // Explicit name field
         images={[
          `https://www.learnsoft.org/${this.props.img}`
      ]}
          description={this.props.description}
          brand={{
            "@type": "Organization",
            "name" : "Learnsoftorg - Top Software Training Institute, Chennai",
          }}
          aggregateRating={{
            "@type": "AggregateRating",
            "ratingValue": "4.98",
            "reviewCount": "17292",
            "worstRating":'1',
            "bestRating":"5"

          }}
        />
         {/* FAQ Schema Implementation */}
         {this.props.faq && this.props.faq.length > 0 &&(
          <FAQPageJsonLd
          mainEntity={this.props.faq.map((item)=>({
            questionName: item.ques,
            acceptedAnswerText: item.ans
          }))}
          />
         )}
            </>
        )
    }
}